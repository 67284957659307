import { pages, subPackages, tabBar } from '@/pages.json'
import dayjs from 'dayjs'
import PLATFORM from './platform'
import _ from 'lodash'

export const getLastPage = () => {
  // getCurrentPages() 至少有1个元素，所以不再额外判断
  // const lastPage = getCurrentPages().at(-1)
  // 上面那个在低版本安卓中打包回报错，所以改用下面这个【虽然我加了src/interceptions/prototype.ts，但依然报错】
  const pages = getCurrentPages()
  return pages[pages.length - 1]
}

/** 判断当前页面是否是tabbar页  */
export const getIsTabbar = () => {
  if (!tabBar) {
    return false
  }
  if (!tabBar.list.length) {
    // 通常有tabBar的话，list不能有空，且至少有2个元素，这里其实不用处理
    return false
  }
  const lastPage = getLastPage()
  const currPath = lastPage.route
  return !!tabBar.list.find((e) => e.pagePath === currPath)
}

/**
 * 获取当前页面路由的 path 路径和 redirectPath 路径
 * path 如 ‘/pages/login/index’
 * redirectPath 如 ‘/pages/demo/base/route-interceptor’
 */
export const currRoute = () => {
  const lastPage = getLastPage()
  const currRoute = (lastPage as any).$page
  // console.log('lastPage.$page:', currRoute)
  // console.log('lastPage.$page.fullpath:', currRoute.fullPath)
  // console.log('lastPage.$page.options:', currRoute.options)
  // console.log('lastPage.options:', (lastPage as any).options)
  // 经过多端测试，只有 fullPath 靠谱，其他都不靠谱
  const { fullPath } = currRoute as { fullPath: string }
  // console.log(fullPath)
  // eg: /pages/login/index?redirect=%2Fpages%2Fdemo%2Fbase%2Froute-interceptor (小程序)
  // eg: /pages/login/index?redirect=%2Fpages%2Froute-interceptor%2Findex%3Fname%3Dfeige%26age%3D30(h5)
  return getUrlObj(fullPath)
}

const ensureDecodeURIComponent = (url: string) => {
  if (url.startsWith('%')) {
    return ensureDecodeURIComponent(decodeURIComponent(url))
  }
  return url
}
/**
 * 解析 url 得到 path 和 query
 * 比如输入url: /pages/login/index?redirect=%2Fpages%2Fdemo%2Fbase%2Froute-interceptor
 * 输出: {path: /pages/login/index, query: {redirect: /pages/demo/base/route-interceptor}}
 */
export const getUrlObj = (url: string) => {
  const [path, queryStr] = url.split('?')
  // console.log(path, queryStr)

  if (!queryStr) {
    return {
      path,
      query: {},
    }
  }
  const query: Record<string, string> = {}
  queryStr.split('&')?.forEach((item) => {
    const [key, value] = item.split('=')
    // console.log(key, value)
    query[key] = ensureDecodeURIComponent(value) // 这里需要统一 decodeURIComponent 一下，可以兼容h5和微信y
  })
  return { path, query }
}
/**
 * 得到所有的需要登录的pages，包括主包和分包的
 * 这里设计得通用一点，可以传递key作为判断依据，默认是 needLogin, 与 route-block 配对使用
 * 如果没有传 key，则表示所有的pages，如果传递了 key, 则表示通过 key 过滤
 */
export const getAllPages = (key = 'needLogin') => {
  // 这里处理主包
  const mainPages = [
    ...pages
      .filter((page) => !key || page[key])
      .map((page) => ({
        ...page,
        path: `/${page.path}`,
      })),
  ]
  // 这里处理分包
  const subPages: any[] = []
  subPackages.forEach((subPageObj) => {
    // console.log(subPageObj)
    const { root } = subPageObj

    subPageObj.pages
      .filter((page) => !key || page[key])
      .forEach((page: { path: string } & Record<string, any>) => {
        subPages.push({
          ...page,
          path: `/${root}/${page.path}`,
        })
      })
  })
  const result = [...mainPages, ...subPages]
  // console.log(`getAllPages by ${key} result: `, result)
  return result
}

/**
 * 得到所有的需要登录的pages，包括主包和分包的
 * 只得到 path 数组
 */
export const getNeedLoginPages = (): string[] => getAllPages('needLogin').map((page) => page.path)

/**
 * 得到所有的需要登录的pages，包括主包和分包的
 * 只得到 path 数组
 */
export const needLoginPages: string[] = getAllPages('needLogin').map((page) => page.path)

export const handleGoPage = (path: string) => {
  uni.navigateTo({
    url: path,
  })
}

export const formatUnixTime = (v: number, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (typeof v === 'number') {
    return dayjs(v * 1000).format(format)
  } else {
    return '--'
  }
}

/** 获取小程序 code */
export const getWxCode = (): Promise<UniApp.LoginRes['code']> =>
  new Promise((resolve, reject) => {
    uni.login({
      provider: 'weixin',
      success: (result) => {
        resolve(result.code)
      },
      fail: (error) => {
        reject(error)
      },
    })
  })

export const tabbarPage = [
  '/pages/order/index',
  '/pages/exchange/index',
  '/pages/history/index',
  '/pages/recruitment/recruitment',
  '/pages/user/user',
]

export const checkIsTabbar = (route) => {
  return tabbarPage.some((page) => route.includes(page))
}

export const handleLoginBack = () => {
  const pages = getCurrentPages()
  const routeIndex = _.findLastIndex(pages, (i) => !i.route.includes('login'))
  if (routeIndex !== -1) {
    const route = pages[routeIndex].$page.fullPath
    const cleanUrl = route.replace(/\?.*(?=#)/, '')
    if (checkIsTabbar(route)) {
      uni.switchTab({ url: cleanUrl })
    } else {
      uni.redirectTo({ url: cleanUrl })
    }
  } else {
    uni.switchTab({ url: '/pages/order/index' })
  }
}

export function generateUniqueString() {
  const timestamp = Date.now() // 获取当前时间戳
  const randomPart = Math.random().toString(36).substring(2, 10) // 随机字符串部分
  return `${timestamp}-${randomPart}`
}

export const loginRedirect = () => {
  if (PLATFORM.isInWeChat) {
    let href = location.href
    const regex = /(\?|&)code=[^&]*(&state=[^&]*)?/g
    href = href.replace(regex, '')
    const url = encodeURIComponent(href)
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa78f09336f65eea0&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
  }
}

export const loginFailedRedirect = () => {
  if (PLATFORM.isInWeChat) {
    let href = location.href
    const regex = /(\?|&)code=[^&]*(&state=[^&]*)?/g
    href = href.replace(regex, '')
    location.href = href
  }
}

export const goLoginPage = (relaunch = false) => {
  const fn = relaunch ? uni.reLaunch : uni.navigateTo
  if (PLATFORM.isH5 && !PLATFORM.isInWeChat) {
    fn({ url: '/pages/user/login/login' })
  } else {
    fn({ url: '/pages/user/login-condition' })
  }
}
