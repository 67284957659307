import { http } from '@/utils/http'

const { get, post, put } = http

// 获取短信验证码
export const getSMS = (data) => post(`/send-sms/`, data, undefined, { noToken: true })

// 获取图形验证码
export const getQuickSMS = (data) =>
  post(`/send-sms-code-nocaptcha/`, data, undefined, { noToken: true, hideErrorToast: true })

// 获取图形验证码
export const getCodeImg = () => get(`/get-captcha/`, undefined, { noToken: true })

export const getUserProfile = () =>
  get('/user/profile/', undefined, {
    hideErrorToast: true,
  })

export const updateUserProfile = (data) => put('/user/profile/', data)

export type LoginParams = {
  phone: string
  sms_code: string
}

export type UserInfo = {
  user_id: number
  phone: string
  token: string
}

export const login = (data: LoginParams) =>
  post<UserInfo>('/driver-login/', data, undefined, { noToken: true })

export const wxLogin = (data) =>
  post('/wechat-login-register/', data, undefined, { noToken: true, hideErrorToast: true })

export const pwdLogin = (data) =>
  post<UserInfo>('/login/', data, undefined, { noToken: true, hideErrorToast: true })

export const logout = () => post('/logout/')

export const flyList = (params = {}) =>
  post('/fly_list/', {
    now_page: 1,
    information: null,
    type: 'page',
    origin: null,
    startPoint: null,
    endPoint: null,
    ...params,
  })

export const getFlyHistoryList = () =>
  post('/fly_list/', {
    now_page: 1,
    type: 'flyListVisit',
  })

export const getFlyHistory = (id: number) =>
  post('/fly_list/', {
    type: 'itemInf',
    id,
  })

export const publishOrder = (data: any) => post('/fly_list/', data)

// 获取发单信息
export const getDispatchInfo = () => get('/dispatch-info/')

// 更新发单信息
export const updateDispatchInfo = (id, data) => put(`/dispatch-info/${id}/`, data)

// 创建发单信息
export const createDispatchInfo = (data) => post(`/dispatch-info/`, data)

// 获取派单信息
export const getTakeOrderInfo = () => get('/take-order-info/')

// 创建派单信息
export const createTakeOrderInfo = (data) => post(`/take-order-info/`, data)

// 更新派单信息
export const updateTakeOrderInfo = (id, data) => put(`/take-order-info/${id}/`, data)

// 更新个人信息
export const updateUserInfo = (data) => put(`/user/profile/`, data)

// 更新密码
export const updatePassword = (data) => post(`/change-password/`, data)

// 重置密码
export const resetPassword = (data) => post(`/reset-password/`, data)
