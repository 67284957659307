class EventCenter {
  private events: Record<string, (...args: any[]) => void> = {}

  private stopEvent: Set<string> = new Set()

  subscribe(event: string, callback: (...args: any[]) => void) {
    this.events[event] = callback
  }

  unsubscribe(event: string) {
    this.events[event] = undefined
  }

  emit(event: string, ...args: any[]) {
    console.log('event: ', event)
    console.log('stopEvent: ', this.stopEvent.has(event))
    if (!this.events[event] || this.stopEvent.has(event)) return

    setTimeout(() => {
      this.events[event](...args)
      if (args?.[0]?.stop === true) {
        this.recovery(event)
      }
    }, 500)
  }

  emitOnce(event: string, ...args: any[]) {
    this.emit(event, ...args)
    this.unsubscribe(event)
  }

  stop(event: string) {
    // this.stopEvent.add(event)
  }

  recovery(event: string) {
    this.stopEvent.delete(event)
  }
}

export default new EventCenter()
