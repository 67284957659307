<template>
  <view @click="goDetail">
    <Card :overhead="data.top >= 1" :praiseMark="data.praise_mark">
      <image
        class="absolute top-0 right-0 w-134rpx h-134rpx"
        v-if="data?.state === 'cancelled'"
        src="@img/deleted.png"
        mode="scaleToFill"
      />
      <image
        class="absolute top-0 right-0 w-100rpx h-100rpx"
        v-if="data?.state === 'completed'"
        src="@img/completed.png"
        mode="scaleToFill"
      />
      <view>
        <view
          :class="['content', props.showPrice ? '' : 'mb', 'whitespace-pre-wrap', 'text-l']"
          :style="`background: ${props.dark ? '#e1e1e1' : props.clicked ? '#f7f7f7' : ''}`"
          v-html="cutData(data.content)"
        ></view>
        <view
          class="text-pri flex justify-end items-baseline"
          v-if="themeStore.displayAmount && props.showPrice"
        >
          <text class="text-s">￥</text>
          <text class="text-m">
            {{ !data.issue_fee || Number(data.issue_fee) === 0 ? '电议' : data.issue_fee }}
          </text>
        </view>
      </view>
      <template v-slot:footer>
        <view class="footer text-s">
          <view class="flex gap-16rpx items-center">
            <view
              class="origin w-48rpx text-xs h-48rpx border-rd-48rpx center whitespace-nowrap"
              :style="`background: url(${data.issue_avatar_url || imgUrlMap.defaultAvatar}) center center / cover no-repeat;`"
            ></view>
            <view class="font-500 ellipsis max-w-260rpx">{{ data.issue_nickname }}</view>
            <wd-tag mark type="primary" :bgColor="originColorMap[data.origin]" custom-class="space">
              {{ originMap[data.origin] }}
            </wd-tag>
          </view>
          <view class="time min-w-96rpx">
            {{ formatUnixTime(data.update_time, 'HH:mm:ss') }}
          </view>
        </view>
      </template>
    </Card>
  </view>
</template>

<script setup lang="ts">
import { originMap, originColorMap, imgUrlMap } from '@/constant'
import { useCommonStore } from '@/store'
import { useThemeStore } from '@/store/theme'
import { formatUnixTime } from '@/utils'

const themeStore = useThemeStore()
const props = defineProps({
  clicked: Boolean,
  dark: Boolean,
  showPrice: Boolean,
  cut: {
    default: false,
    type: Boolean,
  },
  compact: {
    default: false,
  },
  data: {
    type: Object,
    required: true,
  },
})

const commonStore = useCommonStore()

const cutData = (data: string) => {
  const max_word_count = commonStore.systemProfile?.max_word_count || 200
  if (props.compact || (props.cut && data.length > max_word_count)) {
    return data.replace(/<br\s*\/?>/g, '  ')
  } else {
    return data.replace(/(<br\s*\/?>\s*)+/g, '<br />')
  }
}

const emit = defineEmits(['click'])

const goDetail = () => {
  emit('click', props.data.id)
}
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #757575;

  .origin {
    color: white;
    background-size: cover;
  }
}
</style>
