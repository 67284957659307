<template>
  <page-meta :page-style="themeStore.fontSizeStyle.contentFontSize"></page-meta>
  <view :style="themeStore.fontSizeStyle.style">
    <wd-config-provider :themeVars="themeStore.fontSizeStyle.themeVars">
      <slot />
      <wd-toast />
      <wd-message-box custom-class="whitespace-pre-wrap" />
    </wd-config-provider>
  </view>
</template>

<script lang="ts" setup>
import { wxLogin } from '@/service/user'
import { useCommonStore, useUserStore } from '@/store'
import { useThemeStore } from '@/store/theme'
import { getLastPage, getWxCode, goLoginPage, loginFailedRedirect, loginRedirect } from '@/utils'
import eventCenter from '@/utils/eventCenter'
import PLATFORM from '@/utils/platform'
import { useMessage } from 'wot-design-uni'

const message = useMessage()
const themeStore = useThemeStore()
const userStore = useUserStore()
const commonStore = useCommonStore()
const isCommonH5 = !PLATFORM.isInWeChat && !PLATFORM.isMp
const isInView = ref(false)

const loginError = (info) => {
  if (info?.data?.code === 'lock_err') {
    message
      .confirm({
        title: '封号提醒',
        msg: info.data.message,
        closeOnClickModal: false,
        confirmButtonText: '联系客服',
        // cancelButtonText: '取消',
      })
      .then(() => {
        uni.makePhoneCall({
          phoneNumber: commonStore?.systemProfile?.contacts_phone || '18182278739',
        })
      })
      .finally(loginFailedRedirect)
  } else {
    uni.showToast({
      title: '登录失败',
      icon: 'none',
    })
    loginRedirect()
  }
}

onShow(() => {
  isInView.value = true
})

onHide(() => {
  isInView.value = false
})

const fastLogin = async () => {
  if (PLATFORM.isInWeChat) {
    loginRedirect()
  } else if (PLATFORM.isMp) {
    try {
      const invite_id = uni.getStorageSync('invite_id')
      const code = await getWxCode()
      uni.getUserInfo({
        provider: 'weixin',
        onlyAuthorize: true, // 微信登录仅请求授权认证
        success: function (event) {
          const userInfo = event.userInfo
          wxLogin({
            code,
            // wx_name: '微信开放平台-移动应用',
            wx_name: '车队网小程序',
            invite_id,
            nickname: userInfo.nickName,
            headimgurl: userInfo.avatarUrl,
            login_device: PLATFORM.devicesInfo,
          })
            .then(async (res) => {
              if (res.data.token) {
                uni.showToast({
                  title: '登录成功',
                  icon: 'success',
                })
                eventCenter.recovery('otherLogin')
                uni.setStorageSync('token', res.data.token)
                userStore.userInfo = res.data
                userStore.setLogin(true)
                await commonStore.handleGetUserProfile()
                await commonStore.handleGetSystemProfile()
                // uni.switchTab({ url: '/pages/order/index' })
              } else {
                loginError(res)
              }
            })
            .catch((e) => {
              loginError(e)
            })
        },
      })
    } catch (error) {
      loginError(error)
    }
  } else {
    goLoginPage()
  }
}

const handleOtherLogin = ({
  title = '掉线提示',
  msg = '您的账号已在其它设备登录 , 当前设备已注销登录',
}) => {
  const page = getLastPage()
  console.log('page: ', page.route)
  console.log('isInView.value: ', isInView.value)
  // if (!isInView.value) return
  message
    .confirm({
      title,
      msg,
      closeOnClickModal: false,
      confirmButtonText: isCommonH5 ? '去登录' : '一键登录',
      cancelButtonText: '取消',
    })
    .then(fastLogin)
    .catch(() => {
      eventCenter.stop('otherLogin')
    })
}

const handleWxLogin = (code) => {
  wxLogin({
    code,
    // wx_name: '微信开放平台-移动应用',
    wx_name: '车队网公众号',
    login_device: PLATFORM.devicesInfo,
  })
    .then(async (res) => {
      if (res.data.token) {
        uni.showToast({
          title: '登录成功',
          icon: 'success',
        })
        uni.setStorageSync('token', res.data.token)
        userStore.userInfo = res.data
        userStore.setLogin(true)
        await commonStore.handleGetUserProfile()
      } else {
        loginError(res)
      }
    })
    .catch((e) => {
      loginError(e)
    })
}

onLoad(() => {
  // #ifdef H5
  // 微信公众号登录逻辑
  const regex = /code=([^&]+)/
  const match = location.href.match(regex)
  if (match) {
    const code = match[1]
    handleWxLogin(code)
  }
  const route = getLastPage().route
  eventCenter.subscribe(route, handleOtherLogin)
})
</script>
