import { CustomRequestOptions } from '@/interceptors/request'
import eventCenter from './eventCenter'
import { getLastPage } from './index'

const isDev = process.env.NODE_ENV === 'development'

const baseUrl = isDev ? '/api' : '/api'

export const http = <T>(options: CustomRequestOptions) => {
  const url =
    options.url.startsWith('http') || options.url.startsWith('//')
      ? options.url
      : baseUrl + options.url
  // 1. 返回 Promise 对象
  return new Promise<IResData<T>>((resolve, reject) => {
    uni.request({
      ...options,
      header: {
        ...(options.header || {}),
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
      url,
      dataType: 'json',
      // #ifndef MP-WEIXIN
      responseType: 'json',
      // #endif
      withCredentials: true,
      // 响应成功
      success(res) {
        // 状态码 2xx，参考 axios 的设计
        if (res.statusCode >= 200 && res.statusCode < 300) {
          if (res.data.code !== 200) {
            reject({ data: res.data })
            return
          }
          // 2.1 提取核心数据 res.data
          resolve(res.data as IResData<T>)
        } else if (res.statusCode === 403 && res.data?.message === '令牌无效。') {
          uni.removeStorageSync('user')
          uni.removeStorageSync('token')
          uni.removeStorageSync('userInfo')
          const route = getLastPage().route
          eventCenter.emit(route, {
            title: '掉线提示',
            msg: '您的账号已在其它设备登录 , 当前设备已注销登录',
          })
          reject(res)
        } else {
          // 其他错误 -> 根据后端错误信息轻提示
          !options.hideErrorToast &&
            uni.showToast({
              icon: 'error',
              title: (res.data as IResData<T>).message || '请求错误',
            })
          reject(res)
        }
      },
      // 响应失败
      fail(err) {
        uni.showToast({
          icon: 'error',
          title: '网络错误，换个网络试试',
        })
        reject(err)
      },
    })
  })
}

interface ConfigOption {
  hideErrorToast?: boolean
  noToken?: boolean
}

/**
 * GET 请求
 * @param url 后台地址
 * @param query 请求query参数
 * @returns
 */
export const httpGet = <T>(
  url: string,
  query?: Record<string, any>,
  options: ConfigOption = {},
) => {
  return http<T>({
    ...options,
    url,
    query,
    method: 'GET',
  })
}

/**
 * POST 请求
 * @param url 后台地址
 * @param data 请求body参数
 * @param query 请求query参数，post请求也支持query，很多微信接口都需要
 * @returns
 */
export const httpPost = <T>(
  url: string,
  data?: Record<string, any>,
  query?: Record<string, any>,
  options: ConfigOption = {},
) => {
  return http<T>({
    ...options,
    url,
    query,
    data,
    method: 'POST',
  })
}

/**
 * Put 请求
 * @param url 后台地址
 * @param data 请求body参数
 * @param query 请求query参数，put请求也支持query，很多微信接口都需要
 * @returns
 */
export const httpPut = <T>(
  url: string,
  data?: Record<string, any>,
  query?: Record<string, any>,
  options: ConfigOption = {},
) => {
  return http<T>({
    ...options,
    url,
    query,
    data,
    method: 'PUT',
  })
}

http.get = httpGet
http.post = httpPost
http.put = httpPut
