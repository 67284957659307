export const locations = [
  { label: '全部', value: '', patten: [] },
  { label: '太仓附近', value: '太仓附近', patten: ['tc_pattern'] },
  { label: '浦西附近', value: '浦西附近', patten: ['px_pattern'] },
  { label: '外二附近', value: '外二附近', patten: ['w2_pattern'] },
  { label: '外五附近', value: '外五附近', patten: ['w5_pattern'] },
  { label: '川沙附近', value: '川沙附近', patten: ['cs_pattern'] },
  // 外港是   外二+外五+川沙
  { label: '外港附近', value: '外港附近', patten: ['w2_pattern', 'w5_pattern', 'cs_pattern'] },
  { label: '芦潮港附近', value: '芦潮港附近', patten: ['lcg_pattern'] },
  { label: '洋山码头附近', value: '洋山码头附近', patten: ['ys_pattern'] },
  // 芦潮港和洋山 是 芦潮港+洋山
  { label: '芦潮港+洋山码头', value: '洋山附近', patten: ['lcg_pattern', 'ys_pattern'] },
]

export const publishLocations = [
  { label: '太仓附近', value: '太仓附近' },
  { label: '浦西附近', value: '浦西附近' },
  { label: '外二附近', value: '外二附近' },
  { label: '外五附近', value: '外五附近' },
  { label: '川沙附近', value: '川沙附近' },
  { label: '芦潮港附近', value: '芦潮港附近' },
  { label: '洋山码头附近', value: '洋山码头附近' },
]

export const goodsType = [
  { label: '普通货', value: 'ordinary' },
  { label: '危险品', value: 'danger' },
  { label: '大件', value: 'large_sized_object' },
]

export const locationMap = {
  太仓附近: '太仓',
  浦西附近: '浦西',
  外二附近: '外二',
  外五附近: '外五',
  川沙附近: '川沙',
  外港附近: '外港',
  芦潮港附近: '芦潮',
  洋山码头附近: '洋山码头',
  洋山附近: '洋山',
}

export const replay = [
  { label: '每3分钟一次', value: 3 },
  { label: '每5分钟一次', value: 5 },
  { label: '每10分钟一次', value: 10 },
  { label: '每20分钟一次', value: 20 },
  { label: '每1小时一次', value: 60 },
  { label: '每2小时一次', value: 60 * 2 },
  { label: '每4小时一次', value: 60 * 4 },
  { label: '每6小时一次', value: 60 * 6 },
]

export const cycle = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
  { label: 60, value: 60 },
]

export const originMap = {
  online: '线上',
  offline: '线下',
  wechat: '微信',
  recruitment: '招聘',
  car_trade: '交易',
}

export const originColorMap = {
  online: '#fa4350',
  offline: '#ffa500',
  wechat: '#4d80f0',
  recruitment: '#34d19d',
  car_trade: '#f0883a',
}

export const kfUrl = 'https://work.weixin.qq.com/kfid/kfc4ff6546c985ef5e9'

export const corpId = 'ww96dbe4f6d27b20d3'

export const shareimgUrl = 'https://s21.ax1x.com/2024/10/06/pA8q2SH.png'

export const imgUrlMap = {
  defaultAvatar: 'https://fd.cdwwl.com/media/seal_image/2024-10/default-avatar.png',
  userBg: 'https://fd.cdwwl.com/media/seal_image/2024-10/user-bg.png',
  leftBg: 'https://fd.cdwwl.com/media/seal_image/2024-11/left.png',
  rightBg: 'https://fd.cdwwl.com/media/seal_image/2024-11/right.png',
}
