import { getFlyParams, getSystemProfile } from '@/service/system'
import { getUserProfile } from '@/service/user'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useUserStore } from './user'
import { useThemeStore } from './theme'
import eventCenter from '@/utils/eventCenter'

export const useCommonStore = defineStore(
  'common',
  () => {
    const canPlay = ref(true)
    const systemProfile = ref({ is_show_car: false, is_show: true })
    const wxInfo = ref({})
    const userProfile = ref({})
    const intervalTimer = ref([])
    const themeStore = useThemeStore()
    /** 分享状态开关 */
    const isInShareStatus = ref(false)

    /**
     * 打开分享开关，在分钟内分享，会调用分享试用接口
     */
    const openShareStatus = () => {
      isInShareStatus.value = true
      /**
       * 一分钟后自动关闭分享状态开关
       */
      setTimeout(() => {
        isInShareStatus.value = false
      }, 1000 * 60)
    }

    const clearIntervalTimer = () => {
      if (intervalTimer.value?.length === 0) return
      intervalTimer.value.forEach((timer) => {
        clearInterval(timer)
      })
      intervalTimer.value = []
    }

    const addIntervalTimer = (timer) => {
      if (intervalTimer.value?.length === undefined) {
        intervalTimer.value = []
      }
      if (!intervalTimer.value.includes(timer)) {
        intervalTimer.value.push(timer)
      }
    }

    const needRefresh = ref(false)

    const userStore = useUserStore()

    const handleGetUserProfile = async () => {
      try {
        const res = await getUserProfile()
        if (res.code === 200) {
          userProfile.value = res.data
          eventCenter.recovery('otherLogin')
          // userProfile.value.user.fly_list_visit = false
        } else {
          userProfile.value = {}
        }
        return res.data
      } catch (error) {
        userProfile.value = {}
        userStore.setLogin(false)
        userStore.userInfo = {}
      }
    }

    const handleGetSystemProfile = async () => {
      const res = await getSystemProfile()
      if (res.code === 200) {
        let prevVersion = false
        if (systemProfile.value?.contacts_phone) {
          prevVersion = systemProfile.value.app_version
        }
        if (prevVersion !== false && prevVersion !== res.data.app_version) {
          // 是否播放声音
          canPlay.value = true
          // 设置字体大小
          themeStore.setFontSizeThemeMultiple(3)
          // 是否显示金额
          themeStore.displayAmount = false
          // 是否显示紧凑模式
          themeStore.displayCompact = false
        }
        systemProfile.value = res.data
      }
      return res.data
    }

    /** 是否有效 */
    const isActive = computed(() => {
      return (
        !!userProfile.value?.user?.fly_list_time &&
        Date.now() < userProfile.value.user.fly_list_time * 1000
      )
    })

    /** 还有多少天过期 */
    const expiredTime = computed(() => {
      if (!isActive.value) return 0
      if (userProfile.value?.user?.fly_list_time) {
        const tmp = userProfile.value.user.fly_list_time * 1000 - Date.now()
        return Number((tmp / 1000 / 60 / 60 / 24).toFixed(2))
      }
      return 0
    })

    /** 过期提醒，0-7天每天提醒一次，有当天的时间，说明提醒过了，不再提醒 */
    const expiredTimeRemind = ref('')

    const historyTipRemind = ref('')

    const isAbnormal = computed(() => userStore.isLogin && !userProfile.value?.user?.fly_list_visit)

    const systemInfo = uni.getSystemInfoSync()

    const flyParams = ref({})
    const boxSizePatten = [
      { value: '', label: '全部' },
      {
        value: '小箱',
        label: '小箱',
        reg: [
          /(小.{0,2}[箱柜])/g, // /(提小|单小|单个小|一小|1小|一个小|1个小|小空|小重|小开顶|小框架)/g
        ],
        result: "<span style='color:#ff5722;font-weight: 600;'>$1</span>",
      },
      {
        value: '大箱',
        label: '大箱',
        reg: [
          /(大.{0,2}[箱柜])/g, //  /(提大|45尺|大空|大重|大开顶|大框架)/g
        ],
        result: "<span style='color:#ff5722;font-weight: 600;'>$1</span>",
      },
      {
        value: '双拖',
        label: '双拖',
        reg: [/(双拖|双托|双小|两小|俩小|2小|双小|两个小|2个小|俩个小)/g],
        result: "<span style='color:#ff5722;font-weight: 600;'>$1</span>",
      },
    ]

    const handleGetFlyParams = () => {
      return getFlyParams()
        .then((data) => {
          flyParams.value = data || {}
          if (data.min_box_keyword) {
            const minStr = data.min_box_keyword.join('|').replaceAll('*', '\\*')
            boxSizePatten[1].reg[1] = new RegExp('(' + minStr + ')', 'g')
          }
          if (data.max_box_keyword) {
            const maxStr = data.max_box_keyword.join('|').replaceAll('*', '\\*')
            boxSizePatten[2].reg[1] = new RegExp('(' + maxStr + ')', 'g')
          }
          if (data.two_box_keyword) {
            const twoStr = data.two_box_keyword.join('|').replaceAll('*', '\\*')
            boxSizePatten[3].reg[0] = new RegExp('(' + twoStr + ')', 'g')
          }
        })
        .catch((e) => {})
    }

    return {
      isIos: systemInfo.osName === 'ios' && systemInfo.uniPlatform === 'mp-weixin',
      canPlay,
      handleGetUserProfile,
      handleGetSystemProfile,
      systemProfile,
      userProfile,
      isActive,
      expiredTime,
      expiredTimeRemind,
      historyTipRemind,
      wxInfo,
      /** 账号权限异常 */
      isAbnormal,
      /** 是否需要刷新列表，用于发布后回到列表页判断是否需要重新请求 */
      needRefresh,
      /** 飞单轮询的timer */
      intervalTimer,
      /** 清除飞单页面的轮询的timer，某些情况可能还在轮询，所以做下冗余处理 */
      clearIntervalTimer,
      addIntervalTimer,
      flyParams,
      handleGetFlyParams,
      boxSizePatten,
      openShareStatus,
      isInShareStatus,
    }
  },
  {
    persist: true,
  },
)
