import { wxConfig } from '@/service/system'
import { useCommonStore } from '@/store'
import { currRoute } from '@/utils'
import PLATFORM from '@/utils/platform'
import qs from 'qs'

function useShareConfig({
  titleKey = 'wechatShareTitle_fd',
  descKey = 'wechatShareTitle_fdxq',
  title = undefined,
  desc = undefined,
  link = undefined,
  imgUrl = 'https://fd.cdwwl.com/assets/logo-BZDZP-U1.png',
  params = {},
  success = () => {},
}) {
  const commonStore = useCommonStore()

  const init = () => {
    const route = currRoute()
    let shareUrl = toValue(link)
    if (!link) {
      shareUrl = PLATFORM.baseUrl + '/#' + route.path
      const paramsObj = Object.assign(route.query, params)
      if (Object.keys(paramsObj)) {
        const params = qs.stringify(paramsObj)
        shareUrl += '?' + params
      }
    }
    const titleText = toValue(title) || commonStore.systemProfile[titleKey]
    const descText = toValue(desc) || commonStore.systemProfile[descKey]

    // h5分享
    jWeixin.onMenuShareAppMessage({
      title: titleText,
      desc: descText,
      link: shareUrl,
      imgUrl,
      // success: function () {
      //   alert('设置成功')
      // },
    })
    jWeixin.onMenuShareTimeline({
      title: titleText,
      desc: descText,
      link: shareUrl,
      imgUrl,
      success,
      // success: function () {
      //   alert('设置成功')
      // },
    })
  }

  watch([title, desc, link], () => {
    init()
  })

  onShow(async () => {
    if (PLATFORM.isInWeChat) {
      await wxConfig().then((res) => {
        jWeixin.config({
          ...res.data,
          timestamp: res.data.timestamp + '',
          jsApiList: [
            // 'updateAppMessageShareData',
            // 'updateTimelineShareData',
            'checkJsApi',
            'onMenuShareAppMessage',
            'onMenuShareTimeline',
          ],
        })
      })
      if (!commonStore.systemProfile?.wechatShareTitle_fd) {
        await commonStore.handleGetSystemProfile()
      }
      init()
    }
  })
}

export default useShareConfig
